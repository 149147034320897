import { get, has, Model, ref, run, use } from '@expressive/mvc';
import User, { getProject, inProject } from 'api/User';
import Manager from 'table/Manager';

class Data extends Manager {
  ceaTypes = use(Map);
  generate = use(Generator);

  remove = run(async (situation: { uuid: string }) => {
    await User.ExposureSituation.delete(situation.uuid);

    this.data.delete(situation);
    this.update("data");
  })

  async load(){
    const types = new Map();
    const projectUuid = getProject();

    const ceaTypes = await
      User.CEAType.list({ projectUuid, includeGlobals: true });

    const exposureSituations = await
      inProject(User.ExposureSituation);

    for(const type of ceaTypes)
      if(type.isGlobal === false)
        types.set(type.uuid, type);

    this.ceaTypes = types;

    return exposureSituations;
  }
}

export default Data;

class Generator extends Model {
  ready = false;
  parent = has(Data);
  selected = use(Set);
  set = ref(this);

  constructor(){
    super();

    this.on(async () => {
      this.types = await inProject(User.CEAType);
      this.ceas = await inProject(User.CEA);
      this.currentType = this.types[0];
      this.ready = true;
    }, []);
  }

  types: any[] = [];
  ceas?: any[] = undefined;
  currentType?: any = undefined;

  toggle = (cea: any) => {
    if(this.selected.has(cea))
      this.selected.delete(cea);
    else
      this.selected.add(cea)
  }

  focus = get(this, state => {
    const { ceas, currentType } = state;

    if(ceas && currentType)
      return ceas.filter(cea => {
        return cea.typeUuid == currentType.uuid;
      })

    return [];
  });

  valid = get(this, state => {
    const { selected, types } = state;

    const missing = types.filter(value => {
      for(const cea of selected)
        if(cea.typeUuid == value.uuid)
          return false;

      return true;
    });

    return missing.length == 0;
  })

  generate = run(async () => {
    if(!this.valid)
      throw new Error("Atleast one CEA for each type is required.");

    const projectUuid = getProject();
    const ceaList = [ ...this.selected ].map(x => x.uuid);

    const res = await
      User.sdk.generateCeaFullfactForProject({ projectUuid }, { ceaList }, {
        ignoreAllCeaTypeEnforcment: true
      });

    if("valid" in res.data)
      return

    this.parent.data = new Set(res.data.results);
  })
}