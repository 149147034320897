import BlueButton from 'common/button/BlueButton';
import CheckItem from 'common/select/CheckItem';
import SelectBar from 'common/select/SelectBar';
import Spinner from 'common/spinner/Spinner';

import Data from './Data';

const Generate = () => {
  const {
    is: data,
    ready,
    types,
    focus,
    selected,
    toggle,
    generate,
    valid
  } = Data.tap("generate");

  container: {
    display: flex;
    flexDirection: column;
    gap: "10px";
    border: 0xf2f2f2;
    background: 0xfbfbfb;
    radius: 12;
    padding: 10, 20, 20;
    boxSizing: border-box;
    flex: 1;
  }

  header: {
    color: 0x555;
    paddingBottom: 20;
    marginT: 10;
    borderBottom: 0xddd;
    marginB: 5;
    display: flex;
    justifyContent: space-between;
    alignItems: center;
    paddingH: 10;

    h3: {
      font: 1.2, 600;
      margin: 0;
    }

    span: {
      font: 14;
    }
  }

  fields: {
    gridColumns: 1.0, 5.0;
    flex: 1;

    SelectBar: {
      height: fill;
      minHeight: 150;
      minWidth: 150;
    }
  
    selectCEAs: {
      display: grid;
      gridTemplateColumns: "1fr 1fr 1fr";
      gridTemplateRows: "min-content";
      overflowY: auto;
      boxSizing: border-box;
      height: 160;
      padding: 15;
    }
  }

  CheckItem: {
    height: 30;
  }

  loading: {
    display: flex;
    
    Spinner: {
      color: 0xccc;
      margin: 20, auto;
      font: 30;
    }
  }

  BlueButton: {
    padding: 5, 20;
    font: 13, 500;
    margin: auto;
    width: 200;
    marginT: 20;
  }

  if(!ready)
    <loading>
      <Spinner />
    </loading>
  else
    <this>
      <container>
        <header>
          <left>
            <h3>Generate Set of Exposure Situations</h3>
            <span>Here you can pick the CEAs you wish to combine into Exposure situations.</span>
          </left>
        </header>
        <fields>
          <SelectBar
            options={types}
            onSelect={x => data.currentType = x}
            label={x => x.name}
          />
          <selectCEAs>
            {focus.map((cea) => {
              <CheckItem
                checked={selected.has(cea)}
                onClick={() => toggle(cea)}>
                {cea.value}
              </CheckItem>
            })}
          </selectCEAs>
        </fields>
        <BlueButton
          disabled={!valid}
          onClick={generate}>
          Generate
        </BlueButton>
      </container>
    </this>
}

export default Generate;