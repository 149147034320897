import Spinner from "common/spinner/Spinner";
import { useState } from "react";
import { minimumTime } from "utility/timing";

export const Dynamic = (props) => {
  const {
    children,
    disabled,
    ...rest
  } = props;

  const [waiting, setWaiting] = useState(false);
  const onClick = (event) => {
    const { onClick, disabled } = props;

    if(disabled || waiting)
      return;

    const pending = onClick(event);

    if(pending instanceof Promise){
      setWaiting(true);
      pending.then(() => setWaiting(false));
    }
  };

  forward: className;
  bg: $pltPrimary;
  border: $pltPrimaryL;
  shadow: $shadow1, 5;
  flexAlign: center;
  font: 15;
  padding: 0.5, 1.5;
  radius: 8;
  color: white;
  clickable: true;
  marginT: 16;
  transition: "all 0.1s ease-in-out";
  position: relative;
  overflow: hidden;

  css: active: {
    transform: "scale(0.98)"
  }

  if(disabled){
    filter: "brightness(80%)";
    opacity: 0.3;
    cursor: not-allowed;
    transform: "scale(1) !important";
  }
  
  waiting: {
    absolute: fill;
    bg: 0x123ab4dd;
    flexAlign: center;
    fontSize: 24;
    cursor: initial;

    <Spinner />
  }

  <this {...rest} onClick={onClick}>
    {children}
    {waiting && <waiting />}
  </this>
}

export default Dynamic;