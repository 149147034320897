import spinner from './Spinner.css';
import TailSpin from './tailspin.svg';

const Spinner = (props) => {
  TailSpin: {
    forward: className;
  }

  <TailSpin className={spinner.rotating} {...props} />
}

export default Spinner;