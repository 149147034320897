import { get, Global } from '@expressive/mvc';
import User, { getProject, inProject } from 'api/User';

interface HazardEffect {
  name: string;
  uuid: string;
  exists: boolean;
  toggle: () => Promise<void>;
}

class Data extends Global {
  exposureEstimateType = get(getDefaultExposureEstimateType, true);

  hazardEvents = get(getAllHazardousEvents);
  hazardEffectTypes = get(getHazardEffectGroups);

  currentHazardEvent = get(() => this.hazardEvents[0], false);
  currentHazardEffectType = get(() => this.hazardEffectTypes[0], false);

  effects?: HazardEffect[] = undefined;

  constructor(){
    super();
    this.on(this.setEffects);
  }

  async setEffects(){
    const {
      currentHazardEvent,
      currentHazardEffectType
    } = this;

    this.effects = undefined;

    if(!currentHazardEvent || !currentHazardEffectType)
      return;

    const hazards = await User.Hazard.list({
      hazardousEventUuid: currentHazardEvent.uuid
    });

    this.effects = currentHazardEffectType.list.map(({ uuid, name }) => {
      let existing = hazards.find(x => x.hazardEffectUuid == uuid);

      return {
        name,
        uuid,
        get exists(){
          return !!existing;
        },
        toggle: async () => {
          if(existing){
            await User.sdk.deleteHazard({ hazardUuid: existing.uuid }, { confirmed: true })
            existing = undefined;
          }
          else
            existing = await this.createHazard(uuid)
  
          this.update("effects");
        }
      };
    });
  }

  createHazard(hazardEffectUuid: string){
    const {
      exposureEstimateType,
      currentHazardEvent
    } = this;

    return User.Hazard.create(getProject(), {
      hazardEffectUuid,
      exposureEstimateAggregationFunction: "INHERITED",
      hazardousEventUuid: currentHazardEvent!.uuid,
      selectedExposureEstimateTypeUuid: exposureEstimateType!,
      sremUuid: null
    });
  }
}

interface Effect {
  name: string;
  uuid: string;
}

interface HazardEffectGroup {
  name: string;
  list: Effect[];
}

/** Retrieve all HazardEffects and group them by type. */
async function getHazardEffectGroups() {
  const results = await User.HazardEffect.list();
  const groups = new Map<string, HazardEffectGroup>();

  for (const result of results) {
    const uid = result.hazardEffectTypeUuid;
    let group = groups.get(uid);

    if (!group)
      groups.set(uid, group = {
        name: result.hazardEffectType.value.replace(/ Hazards$/, ""),
        list: []
      });

    group.list.push({
      name: result.description!,
      uuid: result.uuid
    });
  }

  return Array.from(groups.values());
}

async function getAllHazardousEvents(){
  return inProject(User.HazardousEvent);
}

async function getDefaultExposureEstimateType(){
  const projectUuid = getProject();
  const project = await User.Project.get(projectUuid);

  return project.defaultExposureEstimateTypeUuid;
}

export default Data;