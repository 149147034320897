import Collapse from 'common/button/Collapse';
import LightBulb from 'common/tooltip/LightBulb';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import Control from './Control';

const ToolTip = ({ children }) => {
  const mount = document.getElementById("portal-tooltip");
  const el = document.createElement("div");

  useEffect(() => {
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el, mount]);

  return createPortal(children, el)
}

export default ToolTip;

export const Portal = () => {
  const { tooltipOpen } = Control.tap();

  margin: 25, 20, 0;
  userSelect: none;
  WebkitUserSelect: none;

  if(!tooltipOpen)
    display: none;

  <this id="portal-tooltip" />
}

export const Toggle = ({ onClick, isOpen }) => {
  const [open, setOpen] = useState(isOpen || false);

  display: inline-block;
  font: 1.1;
  cursor: pointer;
  radius: round;
  padding: 0, 8, 0;
  
  if(open)
    color: 0x2f8cf2;

  Collapse: {
    font: 0.6;
  }

  <this onClick={() => {
    if(onClick)
      onClick(!open);

    setOpen(!open);
  }}>
    <LightBulb on={open} />
    <Collapse open={open} />
  </this>
}