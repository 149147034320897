const UID_CACHE = new WeakMap();

function uniqueId(object: any){
  if(typeof object !== "object")
    return object;

  let uid = UID_CACHE.get(object);

  if(!uid)
    UID_CACHE.set(object, uid = Math.random());
    
  return uid;
}

export default uniqueId;