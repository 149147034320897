import { Provider } from '@expressive/mvc';
import User from 'api/User';
import Manager from 'table/Manager';
import Select from 'table/Select';
import Table, { Column } from 'table/Table';
import Form from 'wizard/Form';
import { Columns } from 'wizard/Page';
import TextArea from 'wizard/TextArea';
import ToolTip from 'wizard/ToolTip';

class Data extends Manager {
  type = User.SREM;
}

const SREMs = () => {
  <Provider for={Data}>
    <Columns>
      <ToolTip>
        <p>
          The pre-existing external features that assist in reducing risk are captured as SREMs.
          These are used when evaluating a Hazard for a particular Hazardous Event and
          corresponding Hazard Effect. Examples include active measures such as a pre-existing
          alarm to assist in hazard avoidability, or an external fence surrounding the system to
          assist with hazard exposure.
        </p>
      </ToolTip>
      <Form>
        <TextArea label="Description" />
        <TextArea label="Remarks" />
      </Form>
      <Table>
        <Select />
        <Column name="Description" />
        <Column name="Remarks" />
      </Table>
    </Columns>
  </Provider>
}

export default SREMs;