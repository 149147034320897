import { useState } from 'react';
import Chevron from 'common/icons/chevron.svg';

const Collapse = ({ onClick, open }) => {
  Chevron: {
    forward: className;

    if(open)
      transform: "rotate(0deg)";
    else
      transform: "rotate(90deg)";
  }

  <Chevron onClick={() => {
    if(onClick)
      onClick(!open);
  }} />
}

export default Collapse;