import { SDKResponseError } from "@harmadillo/gateway-client-sdk";

type ErrorType = SDKResponseError | Error;
type ErrorHandler = (error: ErrorType) => void;

let ERROR_HANDLER: ErrorHandler;

export function setErrorHandler(handler: ErrorHandler){
  ERROR_HANDLER = handler;
}

function onError(error: ErrorType){
  let message: string | undefined;

  if(ERROR_HANDLER)
    ERROR_HANDLER(error);
  else if("status" in error){
    console.error(error.error);

    const issue = error.error;

    message = error.error.message;

    if(issue.enum == "VALIDATION_ERROR"){
      const invalid = issue.stack.validationErrors;

      for(const field of invalid)
        message += `\n\u2022 ${field.message}`;
    }
  }
  else {
    console.error(error);
    message = error.message
  }

  alert(message);
}

function getValidationErrors(errors: Array<any>){
  const out: any = {};

  for(const error of errors){
    const key = error.dataPath.replace(".body.", "").split(".");

    out[key] = error.message;
  }

  return out;
}

export function parseSubmitResponseError(
  reason: ErrorType,
  f: any,
  message?: string
){
  f.setSubmitting(false);

  if(!message)
    message = "Something went wrong. Please try again.";

  if(reason instanceof SDKResponseError){
    if(reason?.error?.validationErrors){
      const errors = getValidationErrors(reason.error.validationErrors);

      f.setErrors(errors);

      const initialMessage = reason?.error?.message ?? "error";
      const json = JSON.stringify(errors, null, 2);

      return `${initialMessage}: ${json}`;
    } 
    else if(reason?.status === 500)
      return message;
    else
      return message ?? reason?.error?.message ?? reason?.message  ?? "Something went wrong. Please try again."
  } 
  else if(reason instanceof Error)
    return reason?.message ?? message;
  else
    return message;
}

export default onError;