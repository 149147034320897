import { ReactNode, useLayoutEffect, useRef } from 'react';

declare namespace useControlled {
  export type Element = HTMLInputElement | HTMLTextAreaElement;

  export interface Props {
    children?: ReactNode;
    initial?: any;
    onCreate?: (element: Element) => void;
    onUpdate?: (value: string) => void;
    onEnterKey?: (value: string) => boolean;
  }
}

const useControlled = (props: useControlled.Props = {}) => {
  const {
    children,
    initial = children,
    onCreate,
    onUpdate,
    onEnterKey
  } = props;

  const inputRef = useRef<useControlled.Element>(null);

  useLayoutEffect(() => {
    const element = inputRef.current;

    if(!element)
      throw new Error("no element found.");

    const onChange = (element: any) => {
      const { key, keyCode, currentTarget } = element;

      if(key === 'Enter' || keyCode === 13){
        if(onEnterKey){
          const clear = onEnterKey(currentTarget.value);
  
          if(clear)
            currentTarget.value = "";
        }
      }
      else if(onUpdate)
        onUpdate(currentTarget.value);
    }

    element.addEventListener("change", onChange);

    if(onCreate)
      onCreate(element);

    if(initial)
      element.value = initial;

    return () => {
      element.removeEventListener("change", onChange);
    }
  }, []);

  return inputRef;
}

export default useControlled;