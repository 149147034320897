import Arrow from 'common/icons/arrow.svg';

const Next = ({ label, left, ...props }) => {
  outer: {
    border: 0xe9e9e9, 1;
    background: 0xf7f7f7;
    color: 0x888;
    forward: className;
    minSize: 2.5;
    display: flex;
    justifyContent: center;
    alignItems: center;
    radius: round;
    cursor: pointer;
    userSelect: none;

    if(left){
      flexDirection: row-reverse;
    }
  }

  outer2: {
    if(label)
      paddingH: 1.0;
  }

  text: {
    padding: 0, 0.3, 0.1;
  }

  Arrow: {
    fontSize: 1.6;
    transform: "rotate(90deg)";
    radius: round;

    if(left)
      transform: "rotate(-90deg)";
  }

  <outer outer2 {...props}>
    {label && (
      <text>
        {label}
      </text>
    )}
    <Arrow />
  </outer>
}

export default Next;