import { Column, Grid } from '@expressive/table';
import Checkbox from 'common/select/Checkbox';

import Manager from './Manager';

const Select = (props) => {
  <Column
    cell={Cell}
    head={Head}
    size={30}
    {...props}
  />
}

export default Select;

const Head = () => {
  const {
    selected,
    selectAll
  } = Manager.tap();

  const { rows } = Grid.tap();

  const checked =
    selected.size > 1 &&
    selected.size === rows.length;

  <Button
    partial={selected.size}
    checked={checked}
    onClick={selectAll}
  />
}

const Cell = ({ row }) => {
  const { selected, select } = Manager.tap();

  <Button
    checked={selected.has(row)}
    onClick={() => select(row)}
  />
}

const Button = ({ onClick, ...rest }) => {
  font: 12;
  alignSelf: center;
  userSelect: none;
  WebkitUserSelect: none;

  <div onClick={onClick}>
    <Checkbox {...rest} />
  </div>
}