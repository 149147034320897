import Control from "./Control";

const Page = ({ children }) => {
  display: flex;
  flexDirection: column;
  overflowY: auto;
  padding: 0, 10, 30;
  gap: "16px";
  flexBasis: 1;

  <this>
    {children}
  </this>
}

export const Columns = ({ children }) => {
  gridColumns: "minmax(300px, 0.1fr)", 1.0;
  gridRows: "minmax(0, 1fr)";
  padding: 0, 10, 30;
  gap: "16px";
  flexBasis: 1;

  <this>
    {children}
  </this>
}

export default Page;