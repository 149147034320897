import { get, Model, use } from '@expressive/mvc';
import SDK from 'api/SDK';
import { getProject } from 'api/User';
import onError from 'utility/onError';
import uniqueId from 'utility/uniqueId';

class Manager extends Model {
  constructor(public type?: SDK.Entity){
    super();
    this.on(this.init, []);
  }

  private async init(){
    const projectUuid = getProject();

    const rows =
      this.load ? await this.load() :
      this.type ? await this.type!.list({ projectUuid }) :
      [];

    this.data = new Set(rows);
    this.ready = true;
  }

  load?(): Promise<unknown[]>;
  create?(): Promise<any>;
  delete?(row: { uuid: string }): Promise<any>;
  patch?(row: { uuid: string }): Promise<any>;

  selected = use(Set);
  data = new Set<any>();
  formdata: any = {};
  ready = false;
  uuid = get(this, state => uniqueId(state.formdata))

  focus = get(this, ({ selected }) => {
    if(selected.size === 1){
      const [ row ] = selected;
      return row;
    }
  })
  
  select = (row: { uuid: string }) => {
    if(this.selected.has(row))
      this.selected.delete(row);
    else
      this.selected.add(row);
  }

  selectAll = () => {
    const { selected, data } = this;

    if(selected.size)
      selected.clear();
    else
      for(const row of data)
        this.selected.add(row);
  }

  createRow = async () => {
    try {
      let inserted: any;
  
      if(this.create)
        inserted = await this.create();
      else if(this.type)
        inserted = await this.type.create(
          getProject(), 
          this.formdata
        );
      else
        throw new Error("Manager has no entity to create.");
  
      this.data.add(inserted);
      this.formdata = {};
    }
    catch(err: any){
      onError(err);
    }
    finally {
      this.update("data");
    }
  };

  patchRow = async () => {
    const { type, patch: method } = this;

    const selected = Array.from(this.selected);
    const requests = selected.map(async row => {
      if(method)
        await method.call(this, row);
      else if(type){
        const data = Object.assign({}, this.formdata);

        delete data.uuid;
    
        for(const key in data)
          if(typeof data[key] !== "string")
            delete data[key];

        const updated = await
          type.update(row.uuid, data);

        Object.assign(row, updated);
      }
      else
        throw new Error("Manager has no entity to delete.");

      this.selected.delete(row);
    })

    try {
      await Promise.all(requests)
      this.formdata = {}; 
    }
    catch(err: any){
      onError(err);
    }
    finally {
      this.update("data");
    }
  };

  deleteRow = async () => {
    const { type, delete: method } = this;

    const selected = Array.from(this.selected);
    const requests = selected.map(async row => {
      if(method)
        await method.call(this, row);
      else if(type)
        await type.delete(row.uuid);
      else
        throw new Error("Manager has no entity to delete.");

      this.selected.delete(row);
      this.data.delete(row);
    });

    try {
      await Promise.all(requests);
      this.formdata = {};
    }
    catch(err: any){
      onError(err);
    }
    finally {
      this.update("data");
    }
  };
}

export default Manager;