import { useCallback, useEffect, useMemo, useRef } from "react";

const DropDown = ({ children, onClose, active }) => {
  if(active)
    <Container onClickOutside={onClose}>
      {children}
    </Container>
}

export default DropDown;

const Container = ({ onClickOutside, children }) => {
  const ref = useOnClickOutside(onClickOutside);

  position: absolute;
  display: flex;
  top: "100%";
  marginT: 5;
  marginR: 20;
  left: 0;
  right: 0;
  zIndex: 20;

  <this ref={ref}>
    {children}
  </this>
}

function useOnClickOutside(onClick) {
  const ref = useRef(null);
  const listener = useCallback((event) => {
    if(!ref.current)
      return;

    if(ref.current.contains(event.target))
      return;

    if(onClick)
      onClick(event);

    event.preventDefault();
  });

  useEffect(() => {
    document.addEventListener("click", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("click", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [])

  return ref;
}