import DropDown from 'common/DropDown';
import MultiSelect from 'common/MultiSelect';
import useAsync from 'utility/useAsync';

import DataGrid from './DataGrid';

const FilterMenu = ({ column, active, toggle }) => {
  const { filters, is: grid } = DataGrid.tap();
  const { options } = column.props;

  const name = column.name.toLowerCase();
  const opts = typeof options == "function"
    ? useAsync(options) : options;

  <DropDown
    active={active}
    onClose={() => toggle(false)}>
    <MultiSelect
      options={opts}
      selected={filters[name]}
      onSelect={select => {
        grid.filters = {
          ...filters,
          [name]: select
        }
      }}
    />
  </DropDown>
}

export default FilterMenu;