const Input = ({ label, ...props }) => {
  forward: className;
  position: relative;
  display: flex;
  flexDirection: column;

  label: {
    display: block;
    fontSize: 0.85;
    color: 0x999;
    marginB: 5;
    marginL: 5;
  }

  input: {
    forward: ref;
    display: block;
    color: $text2;
    border: 0xd0d0d0;
    boxSizing: border-box;
    radius: 4;
    padding: 8, 10;
    fontSize: 1.0;
    height: 38;

    css: focus: {
      borderColor: "rgb(158, 196, 231)";
      outline: none;
    }

    if(props.disabled){
      border: 0xd9d9d9;
      background: 0xe9e9e9;
    }
  };

  <this>
    {label && (
      <label>{label}</label>
    )}
    <input type="text" {...props} />
  </this>
}

export default Input;