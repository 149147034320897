import { Provider } from '@expressive/mvc';
import BlueButton from 'common/button/BlueButton';
import PleaseHold from 'common/spinner/PleaseHold';
import { Suspense } from 'react';

import Wizard from '../src';
import Auth from './Auth';
import Header from './Header';
import SelectProject from './SelectProject';
import { userSDK } from './gateway';

const App = () => {
  const { error, is: auth } = Auth.use();

  height: "100vh";
  fontFamily: Lato;
  textAlign: center;
  font: 16;

  PleaseHold: {
    height: fill;
  }

  <Provider for={auth}>
    <Suspense fallback={<PleaseHold />}>
      {error
        ? <Error message={error.message} retry={auth.retry} />
        : <WizardUI />
      }
    </Suspense>
  </Provider>
}

const Error = ({ message, retry }) => {
  height: "100vh";
  flexAlign: center, down;
  color: maroon;

  error: {
    marginT: 10;
    background: 0xeee;
    padding: 6, 10;
    radius: 5;
    color: 0x555;
    font: 0.9;
  }

  <this>
    Login failed with error: <br/>
    <error>
      {message}
    </error>
    <BlueButton onClick={retry}>
      Try Again
    </BlueButton>
  </this>
}

const WizardUI = () => {
  const {
    logout,
    projectUuid
  } = Auth.tap();

  gridRows: min, "minmax(0, 1fr)";
  height: "100vh";
  padding: 0, 20;

  Wizard: {
    maxWidth: 1200;
    margin: auto;
    height: fill;
  }

  <this>
    <Header logout={logout} />
    {projectUuid ?
      <Wizard
        sdk={userSDK}
        projectUuid={projectUuid}
        onNext={(from, to) => {
          console.log(`navigated from ${from} to ${to}`)
        }}
        onDone={() => {
          alert("yay we did it!")
        }}
      /> :
      <SelectProject />
    }
  </this>
}

export default App;