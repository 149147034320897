import Spinner from "./Spinner";

const PleaseHold = ({ children }) => {
  forward: className;
  flexAlign: center, down;
  color: $text2;

  plzHold: {
    fontSize: 1.0;
  }

  Spinner: {
    fontSize: 3.0;
    color: $pltPrimary;
    marginB: 20;
  }

  <this>
    <Spinner />
    <plzHold>
      {children || "Loading..."}
    </plzHold>
  </this>
}

export default PleaseHold;