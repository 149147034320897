import CheckItem from './select/CheckItem';

const MultiSelect = ({
  options = [],
  selected = [],
  onSelect = () => {}
}) => {
  display: inline-block;
  bg: white;
  border: 0x1;
  radius: 7;
  shadow: 0x2;
  padding: 15, 12, 5;

  CheckItem: {
    whiteSpace: nowrap;
    minWidth: "100%";
    boxSizing: border-box;
  }

  items: {
    overflowY: auto;
    overflowX: hidden;
    maxHeight: 300;
    paddingR: 15;
  }

  clear: {
    textAlign: center;
    radius: 5;
    bg: 0x05;
    border: 0x1;
    padding: 3, 0;
    margin: 10, 0;

    onClick = () => onSelect(undefined);
  }

  <this>
    <items>
      {options.map(name => {
        const checked = selected.includes(name);

        <CheckItem
          key={name}
          checked={checked}
          onClick={() => {
            let selection = checked
              ? selected.filter(x => x !== name)
              : selected.concat(name);

            if(selection.length == 0)
              selection = undefined;
            
            onSelect(selection, name);
          }}>
          {name}
        </CheckItem>
      })}
    </items>
    <clear>
      Clear
    </clear>
  </this>
}

export default MultiSelect;