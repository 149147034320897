import { Provider } from '@expressive/mvc';
import User from 'api/User';
import Manager from 'table/Manager';
import Select from 'table/Select';
import Table, { Column } from 'table/Table';
import Form from 'wizard/Form';
import { Columns } from 'wizard/Page';
import TextArea from 'wizard/TextArea';
import ToolTip from 'wizard/ToolTip';

class Data extends Manager {
  type = User.Assumption;
}

const Assumptions = () => {
  <this>
    <Description />
    <Provider for={Data}>
      <Columns>
        <Form>
          <TextArea label="Description" />
        </Form>
        <Table>
          <Select />
          <Column name="Description" />
        </Table>
      </Columns>
    </Provider>
  </this>
}

const Description = () => {
  <ToolTip>
    <p>
      The first step in completing a HARA is defining the list 
      of assumptions associated with the product being analyzed. 
      These assumptions help clarify vocabulary and determine scope for the analysis. 
      This can be a bit painful, but this sets you up for saving a lot of time later! 
      A few examples may include: 
      <ul>
        <li>A robot in the stopped position is safe (i.e. "moving out of the way" is not a required maneuver to assure safety)</li>
        <li>"Parked" refers to the condition of being in an intended storage or staging location.</li>
      </ul>
    </p>
  </ToolTip>
}

export default Assumptions;