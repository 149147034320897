const TextArea = ({ label, ...props }) => {
  forward: className;
  position: relative;
  display: flex;
  flexDirection: column;

  label: {
    display: block;
    marginB: 5;
    marginL: 5;
    color: 0x999;
    fontSize: 0.85;
  }

  textarea: {
    forward: ref;
    outline: none;
    flex: 1;
    display: block;
    width: fill;
    resize: vertical;
    radius: 4;
    border: 0xd9d9d9;
    minHeight: 3.0;
    padding: 16, 16;
    boxSizing: border-box;
    fontSize: 1.0;
    fontFamily: sans-serif;
    color: 0x666;

    if(props.disabled){
      border: 0xd9d9d9;
      background: 0xe9e9e9;
    }
  }

  <this>
    {label && (
      <label>{label}</label>
    )}
    <textarea {...props} />
  </this>
}

export default TextArea;