import Next from 'common/button/Next';
import { ProgressBar } from 'common/ProgressBar';

import Control from './Control';
import { Portal, Toggle } from './ToolTip';

const WizardNav = () => {
  const {
    is: control,
    router: {
      done,
      pages,
      current,
      isFirstPage
    }
  } = Control.tap();

  padding: 20, 16, 25;
  position: relative;

  navigation: {
    gridColumns: 50, 1.0, 120;
    justifyItems: center;
    alignItems: center;
    margin: 0, 10, 15;
  }

  header: {
    font: 1.1, 600;
    justifySelf: flex-start;
    marginL: 10;
    fontFamily: "Montserrat";

    if(isFirstPage){
      transform: "translateX(-40px)"
    }
  }

  <this>
    <navigation>
      <BackButton />
      <header>
        {current ? current.header || current.name : false}
        <Toggle onClick={yes => control.tooltipOpen = yes} />
      </header>
      <NextButton />
    </navigation>
    <ProgressBar
      fill={done.size}
      fill2={pages.indexOf(current)}
      steps={pages.length}
    />
    <Portal />
  </this>
}

export default WizardNav;

const BackButton = () => {
  const { isFirstPage, is: wizard } = Control.router();

  if(isFirstPage){
    opacity: 0;
    pointerEvents: none;
  }

  <Next left onClick={() => wizard.goBack()} />
}

const NextButton = () => {
  const { isLastPage, is: wizard } = Control.router();

  if(isLastPage)
    <Next label={"All Done!"} onClick={() => wizard.onDone()} />
  else
    <Next label={"Next"} onClick={() => wizard.next()} />
}