import { get } from '@expressive/mvc';
import { Grid } from '@expressive/table';

class DataGrid extends Grid {
  data?: any[] = undefined;

  sortColumn?: Grid.Column<any> = undefined;
  sortDirection = 1;
  filters = {} as { [key: string]: any };

  rows = get(() => this.sortRows);

  sort = (key: Grid.Column<any>)  => {
    if(this.sortColumn !== key)
      this.sortColumn = key;
    else if((this.sortDirection *= -1) > 0)
      this.sortColumn = undefined;
  }

  private sortRows(){
    const {
      data,
      sortColumn,
      sortDirection,
      filters
    } = this;

    if(!data)
      return;

    const output = Array.from(data).filter((item: any) => {
      for(const key in filters){
        const filter = filters[key];
        
        if(Array.isArray(filter))
          if(!filter.includes(item[key]))
            return false;
      }

      return true;
    });

    if(sortColumn){
      const {
        value,
        name,
        props: { sortBy }
      } = sortColumn!;

      const get = 
        typeof sortBy == "function" ? sortBy :
        typeof sortBy == "string" ? (x: any) => x[sortBy] :
        typeof value == "function" ? value :
        (x: any) => {
          if(typeof value == "string")
            return x[value];
          
          return x[name.toLowerCase()];
        }

      output.sort((a, b) => {
        return get(a) < get(b)
          ? -sortDirection
          : +sortDirection;
      })
    }
    else {
      function created(row: any){
        if(typeof row == "object" && "created" in row)
          return new Date(row.created).getTime();
      
        return 0;
      }

      output.sort((a, b) => {
        return created(a) > created(b) ? -1 : 1;
      })
    }

    return output;
  }
}

export default DataGrid;