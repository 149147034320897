import Base, { Column, Grid } from '@expressive/table';
import Animate from 'common/Animate';
import PleaseHold from 'common/spinner/PleaseHold';
import { useLayoutEffect, useState } from 'react';

import DataGrid from './DataGrid';
import Manager from './Manager';

export { Column };

const Table = (props) => {
  const data = Manager.tap(state => [...state.data]);

  Base: {
    forward: className;
    position: relative;
    textAlign: left;
    font: 14;
    flex: 1;
    radius: 10;
    minHeight: 200;
  }

  <Base
    for={DataGrid}
    data={data}
    header={Header}
    row={Row}
    empty={NoResults}
    {...props}
  />
}

export default Table;

const Row = ({ children }) => {
  Animate: {
    padding: 10, 10;
    margin: 0, 10;
    color: $grey4;
    alignItems: center;
    borderBottom: 0xddd;

    css: lastChild: {
      borderBottom: none;
    }
  }

  <Animate>
    {children}
  </Animate>
}

const Header = ({ children, padding }) => {
  forward: className;
  padding: 8, 20;
  color: $text2;
  background: 0xf8f8f8;
  border: 0xf2f2f2;

  if(padding)
    borderRadius: "8px 8px 0 8px";
  else {
    radius: 8;
    marginB: 5;
  }

  <this>
    {children}
  </this>
}

const NoResults = () => {
  const { ready } = Manager.tap();

  container: {
    height: fill;
    boxSizing: border-box;
    absolute: fill, 10, 0;

    inner: {
      radius: 12;
      color: $text3;
      height: 50;
      bg: 0xfbfbfb;
      border: 0xefefef;
      textAlign: center;
      flexAlign: center;
      padding: 15, 0;
      shadow: 0x07, 3, 1;
    }
  }

  PleaseHold: {
    absolute: fill;
    maxHeight: 400;
  }

  if(!ready)
    <PleaseHold />
  else
    <container>
      <inner>
        - No items yet! -
      </inner>
    </container>
}