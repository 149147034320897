import Button from 'common/button/Dynamic';
import Manager from 'table/Manager';

const Form = ({ children }) => {
  border: 0xf2f2f2;
  boxSizing: border-box;
  background: 0xf8f8f8;
  radius: 12;
  padding: 20;

  fields: {
    forward: className;
    display: flex;
    flexDirection: column;
  }

  <this>
    <fields>
      {children}
    </fields>
    <Buttons />
  </this>
}

export const Buttons = () => {
  const {
    selected,
    createRow,
    patchRow,
    deleteRow
  } = Manager.tap();

  display: flex;
  justifyContent: flex-end;

  BlueButton: {
    radius: 6;
    padding: 8, 20;
  }

  red: {
    background: "rgb(224, 117, 117)";
    border: "1px solid rgb(195, 99, 99)";
    marginR: 10;
  }

  if(!selected.size)
    <Button key="blue" onClick={createRow}>
      Create
    </Button>
  else
    <this>
      <Button red onClick={deleteRow}>
        Delete
      </Button>
      <Button key="blue" onClick={patchRow}>
        Update
      </Button>
    </this>
}

export default Form;