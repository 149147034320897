import { Model, use } from '@expressive/mvc';
import { getProject } from 'api/User';
import React from 'react';

import { Router } from './Router';

interface Page {
  name: string;
  header?: string;
  component: React.VFC;
}

class Control extends Model {
  tooltipOpen = false;
  project = getProject();

  router = use(() => {
    const router = new Router<Page>();

    router.on("pages", this.tryToResume, true);
    router.on(({ current, previous }) => {
      if(previous)
        localStorage.setItem("wizard-state", `${this.project}:${current.name}`);
    });

    return router;
  });

  onDone?: () => void = undefined;

  protected tryToResume = () => {
    const state = localStorage.getItem("wizard-state");

    if(!state)
      return;

    const [sessionProject, pageName] = state.split(":");

    if(sessionProject !== this.project)
      return;

    const { done, pages, visited } = this.router;
    const skipTo = pages.findIndex(x => x.name === pageName);

    if(skipTo < 1){
      localStorage.removeItem("wizard-state");
      return;
    }

    pages.slice(0, skipTo).forEach(page => {
      visited.add(page);
      done.add(page);
    });

    this.router.goto(skipTo);
  }

  static router(){
    return this.tap("router");
  }

  static addPage(props: any){
    this.get($ => {
      $.router.addPage(props);
    });
  }
}

export default Control;