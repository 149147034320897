import Checkmark from 'common/select/checkmark.svg';

export const ProgressBar = ({ fill2, fill, steps }) => {
  height: 3;
  position: relative;
  background: 0xf8f8f8;

  Bar: {
    absolute: fill;

    complete: {
      color: "rgb(157 202 147 / 30%)";
    }

    position: {
      color: "rgb(157 202 147)";
    }
  }

  <this>
    <Bar position steps={steps} done={fill2} />
    <Bar complete steps={steps} done={fill} />
  </this>
}

const Bar = ({ steps, done }) => {
  const complete = done / (steps - 1);
  const percent = Math.min(complete, 1) * 100 - 100;

  forward: className;
  position: absolute;
  overflow: hidden;
  radius: 3;
  
  complete: {
    absolute: fill;
    background: "currentcolor";
    transform: `translateX(${percent}%)`;
    transition: "transform 0.15s cubic-bezier(.09,.87,.63,.97)";
    radius: round;
  }

  <this>
    <complete />
  </this>
}