import Model, { get, set } from '@expressive/mvc';

import { loadUserSession, login, logout, userSDK } from './gateway';

interface User {
  firstName: string;
  lastName: string;
  email: string;
  uuid: string;
  created: string;
}

export default class Auth extends Model {
  constructor(){
    super();
    this.init();
  }

  projectUuid = "";
  loading = true;
  error?: Error;

  user = set<User>();
  token = set<string>();

  projects = get(async () => {
    // suspend if this does not exists.
    void this.token;

    const res = await userSDK.getProjectList();
    const { results } = res.data;

    if(results.length == 1){
      this.projectUuid = results[0].uuid;
      await this.on(true);
    }
    
    return results.map(x => ({
      label: x.name,
      value: x.uuid
    }));
  });

  async init(){
    try {
      const session = await loadUserSession();
  
      this.user = session.user;
      this.token = session.token;
      this.loading = false;
    }
    catch(err: any){
      this.error = err;
    }
  }

  retry(){
    login();
  };

  logout(){
    logout();
  }
}