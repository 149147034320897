import { Provider } from '@expressive/mvc';
import CheckItem from 'common/select/CheckItem';
import SelectBar from 'common/select/SelectBar';
import PleaseHold from 'common/spinner/PleaseHold';
import { Suspense } from 'react';
import Page from 'wizard/Page';
import ToolTip from 'wizard/ToolTip';

import Data from './Data';

const Hazards = () => {
  <Provider for={Data}>
    <Page>
      <Description />
      <HazardousEvents />
    </Page>
  </Provider>
}

const HazardousEvents = () => {
  const {
    is: data,
    hazardEffectTypes,
    hazardEvents,
    effects
  } = Data.tap();

  gridColumns: min, min, auto;
  overflow: auto;

  selectHazards: {
    padding: 14;
    margin: 15, 0;
    overflow: auto;
  
    if(effects)
      for(const effect of effects)
        <CheckItem
          key={effect.uuid}
          checked={effect.exists}
          onClick={effect.toggle}>
          {effect.name}
        </CheckItem>
    else {
      flexAlign: center;
      
      <PleaseHold />
    }
  }

  <this>
    <SelectBar
      onSelect={x => data.currentHazardEvent = x}
      options={hazardEvents}
      label={x => x.hrId}
    />
    <SelectBar
      onSelect={x => data.currentHazardEffectType = x}
      options={hazardEffectTypes}
      label={x => x.name}
    />
    <selectHazards />
  </this>
}

const Description = () => {
  <ToolTip>
    <p>
      A HARA is made up of a collection of Hazard Effects paired with Hazardous Events.
      Each potential Hazard must be evaluated in terms of its Severity (S) and
      Avoidability (C). Please note that the Exposure (E) will be automatically calculated
      from the applicable CEA values. You and your team will do the evaluation for
      Severity and Avoidability in the next step as a Risk Assessment.
    </p>
    <p>
      Below, we will take you through each Hazardous Event you have identified in
      the previous steps. From here, you tell us which Hazard Effects make sense
      for each Hazardous Event. 
    </p>
  </ToolTip>
}

export default Hazards;