import { get, MVC, use } from '@expressive/mvc';

class Router<T extends {}> extends MVC {
  index = 0;
  pages: T[] = [];

  current = get(() => this.getPage);
  previous?: T = undefined;

  isFirstPage = get(this, $ => $.index == 0)
  isLastPage = get(this, $ => $.index == $.pages.length - 1)

  visited = use<T>(Set);
  done = use<T>(Set);

  public goto(index: number){
    if(index < 0 || index === this.pages.length)
      return;

    this.index = index;
    return this.current;
  }
  
  protected onNext(next: T){
    const { current, done, visited } = this;

    visited.add(next);

    if(current && !done.has(next))
      done.add(current);
  }

  protected getPage(){
    const { pages, index, current } = this;

    const next = pages[index];

    if(next){
      if(current)
        this.previous = current;

      this.is.onNext(next);
    }

    return next;
  }
  
  public addPage(info: T){
    this.pages.push(info);
    this.update("pages");
  }

  public goBack(){
    return this.goto(this.index - 1);
  }

  public next(){
    return this.goto(this.index + 1);
  }
}

export { Router }