import SDK from './SDK';

/** Convert SDK to simpified entities. */
export function getEntities<T extends SDK.Original>(
  sdk: T, into?: SDK.Entities<T>){

  const entities = into || {} as any;
  const prototype = Object.getPrototypeOf(sdk);

  for(const key of Object.getOwnPropertyNames(prototype)){
    const match = /^get(\w+)List$/.exec(key);

    if(!match)
      continue;

    const name = match[1];

    Object.defineProperty(entities, name, {
      configurable: true,
      value: createEntity(name, sdk)
    });
  }

  Object.defineProperty(entities, "sdk", {
    configurable: true,
    value: sdk
  });

  return entities as SDK.Entities<T>
}

function createEntity(entity: string, sourceSDK: any){
  const uuidName = weirdLowercase(entity) + "Uuid";

  return {
    list: (query: any, mapper: any) => {
      if(typeof query == "function"){
        mapper = query;
        query = {};
      }

      const request = sourceSDK[`get${entity}List`](null, query);
      let results = request.then(getResults);

      if(typeof mapper == "function")
        results = results.then((r: any) => r.map(mapper));

      return results;
    },
    create: (params: any, data: any) => {
      if(typeof params == "string")
        params = { projectUuid: params };

      if(!data){
        data = params;
        params = null;
      }

      const method = sourceSDK[`create${entity}`] || sourceSDK[`create${entity}User`];

      return method.call(sourceSDK, params, data).then(getData)
    },
    get: (uuid: string) => (
      sourceSDK[`get${entity}`]({ [uuidName]: uuid }).then(getData)
    ),
    getOne: (arg: {}, orThrow?: boolean) => (
      sourceSDK[`get${entity}List`](null, arg).then((res: any) => {
        const result = res.data.results[0];

        if(!result && orThrow)
          throw new Error(`${entity} not found with provided constraints`);

        return result;
      })
    ),
    update: (uuid: string, data: any) => (
      sourceSDK[`update${entity}`]({ [uuidName]: uuid }, data).then(getData)
    ),
    delete: (uuid: string) => (
      sourceSDK[`delete${entity}`]({ [uuidName]: uuid }).then(() => {})
    )
  }
}

function weirdLowercase(s: string){
  return s.replace(
    /^([A-Z]+?)([A-Z]?[a-z]+|$)/,
    (_, lower, rest) => {
      return lower.toLowerCase() + rest;
    }
  )
}

function getData(x: any){
  return x.data;
}

function getResults(x: any){
  return x.data.results
}