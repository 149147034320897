import BaseSelect from 'react-select';
import Manager from 'table/Manager';

const Select = ({
  label,
  options,
  multiple,
  onChange,
  field,
  initial
}) => {
  const {
    selected,
    focus,
    formdata,
    uuid
  } = Manager.tap();

  const disable = !multiple && selected.size > 1;
  const defaultValue = typeof initial == "function"
    ? Array
      .from(selected)
      .map(initial)
      .reduce((p, n) => p && n !== p ? undefined : n, undefined)
    : focus && focus[field];

  forward: className;
  position: relative;
  display: flex;
  flexDirection: column;
  margin: 5;

  label: {
    display: block;
    marginB: 5;
    marginL: 5;
    color: 0x999;
    fontSize: 0.85;
  }

  <this>
    {label && (
      <label>{label}</label>
    )}
    <BaseSelect
      key={uuid + selected.size}
      options={options}
      isDisabled={disable}
      defaultValue={defaultValue && options
        ? options.find(x => x.value === defaultValue)
        : undefined
      }
      onChange={({ value }) => {
        if(onChange)
          onChange(value, formdata);
        else if(field)
          formdata[field] = value;
      }}
    />
  </this>
}

export default Select;