import { get } from '@expressive/mvc';
import User, { getProject, inProject } from 'api/User';
import Manager from 'table/Manager';

export class Data extends Manager {
  ceaTypes = get(() => inProject(User.CEAType));

  typeFilterOptions = get(() => {
    return this.ceaTypes.map(x => x.name)
  }, false);

  typeSelectOptions = get(() => {
    return this.ceaTypes.map(x => ({
      label: x.name,
      value: x.uuid
    }));
  }, false);

  frequencySelectOptions = get(async () => {
    const { discreteValueList } = await
      User.ExposureEstimateType.getOne({
        name: "Frequency"
      }, true);

    return discreteValueList.map(x => ({
      label: x.guidance,
      value: x.uuid
    }))
  }, false)

  async load(){
    const ceas = await inProject(User.CEA);

    return ceas.map(cea => {
      const [
        frequency,
        duration
      ] = cea.estimateList;

      return {
        ...cea,
        frequency,
        duration
      }
    });
  }

  async patch(row: any){
    const {
      value,
      ceaFrequencyDiscreteUuid,
      typeUuid,
      description,
      duration
    } = this.formdata;

    if(duration !== undefined)
      row.duration = await
        User.ExposureEstimate.update(
          row.duration.uuid,
          { value: duration }
        )

    if(ceaFrequencyDiscreteUuid)
      row.frequency = await
        User.ExposureEstimate.update(row.frequency.uuid, {
          discreteValueUuid: ceaFrequencyDiscreteUuid
        })

    if(value || typeUuid || description){
      const result = await
        User.CEA.update(row.uuid, {
          value,
          typeUuid,
          description
        });

      row.value = result.value;
      row.description = result.description;
      row.typeUuid = result.typeUuid;
      row.type = result.type;
    }
  }

  async create(){
    const {
      ceaFrequencyDiscreteUuid,
      description,
      duration,
      typeUuid,
      value
    } = this.formdata;
    
    const projectUuid = getProject();

    const types = await User.ExposureEstimateType.list();
    const frequencyTypeUuid = types.find(x => x.name == "Frequency")?.uuid!;
    const durationTypeUuid = types.find(x => x.name == "Duration")?.uuid!;
      
    const { data } = await 
      User.sdk.createCEAUser({ projectUuid }, {
        value,
        typeUuid,
        description
      });

    const durationEstimate = await
      User.ExposureEstimate.create(projectUuid, {
        value: duration,
        ceaUuid: data.uuid,
        typeUuid: durationTypeUuid
      })

    const frequencyEstimate = await
      User.ExposureEstimate.create(projectUuid, {
        discreteValueUuid: ceaFrequencyDiscreteUuid,
        ceaUuid: data.uuid,
        typeUuid: frequencyTypeUuid
      });

    this.update("selected");

    return {
      ...data,
      frequency: frequencyEstimate,
      duration: durationEstimate
    };
  }
  
  async delete(row: { uuid: string }){
    const ceaUuid = row.uuid;
    const esimtates = await User.ExposureEstimate.list({ ceaUuid });

    await Promise.all(esimtates.map(estimate => {
      return User.sdk.deleteExposureEstimate({
        exposureEstimateUuid: estimate.uuid
      }, { confirmed: true })
    }));

    await User.sdk.deleteCEA({ ceaUuid }, { confirmed: true })
  }
}