import { get } from '@expressive/mvc';
import User, { getProject, inProject } from 'api/User';
import Manager from 'table/Manager';

export class Data extends Manager {
  type = User.HazardousEvent;
  ceaTypes = undefined;

  async create(){
    const {
      sourceType,
      sourceUuid,
      ...formdata
    } = this.formdata;
    
    formdata[sourceType + "Uuid"] = sourceUuid;

    return User.HazardousEvent.create(getProject(), formdata);
  }

  async delete(row: { uuid: string }){
    User.sdk.deleteHazardousEvent({
      hazardousEventUuid: row.uuid
    },
    { confirmed: true })
  }

  situations = get(async () => {
    const list = await inProject(User.ExposureSituation);

    return list.map(x => {
      const label = x.description!
        .replace(/^\w+: /, "")
        .replace(/, /g, " -- ");

      return {
        label,
        value: x.uuid
      };
    });
  }, false);

  typeOptions = get(async () => {
    const malfunctions = await inProject(User.Malfunction);
    const humanTasks = await inProject(User.HumanTask);
    const misuses = await inProject(User.Misuse);

    return [
      {
        label: "Malfunction",
        options: malfunctions.map(x => ({
          label: x.description,
          value: {
            type: "malfunction",
            uuid: x.uuid
          }
        }))
      },
      {
        label: "HumanTask",
        options: humanTasks.map(x => ({
          label: x.description,
          value: {
            type: "humanTask",
            uuid: x.uuid
          }
        }))
      },
      {
        label: "Misuse",
        options: misuses.map(x => ({
          label: x.description,
          value: {
            type: "misuse",
            uuid: x.uuid
          }
        }))
      }
    ];
  }, false);
}
