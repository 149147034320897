import { Provider } from '@expressive/mvc';
import User from 'api/User';
import Manager from 'table/Manager';
import Select from 'table/Select';
import Table, { Column } from 'table/Table';
import Form from 'wizard/Form';
import { Columns } from 'wizard/Page';
import TextArea from 'wizard/TextArea';
import ToolTip from 'wizard/ToolTip';

class Data extends Manager {
  type = User.Misuse;
}

const Misuses = () => {
  <Provider for={Data}>
    <Columns>
      <ToolTip>
        <p>
          A Misuse is the description of an event in which the system being used for something
          in which it is not intended. 
        </p>
        <p>
          An example may include: Robot traverses on unapproved surface.
        </p>
      </ToolTip>
      <Form>
        <TextArea label="Description" />
      </Form>
      <Table>
        <Select />
        <Column name="Description" />
      </Table>
    </Columns>
  </Provider>
}

export default Misuses;