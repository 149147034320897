import { Provider } from '@expressive/mvc';
import SelectColumn from 'table/Select';
import Table, { Column } from 'table/Table';
import uniqueId from 'utility/uniqueId';
import Form from 'wizard/Form';
import Page from 'wizard/Page';
import Select from 'wizard/Select';
import TextArea from 'wizard/TextArea';
import ToolTip from 'wizard/ToolTip';

import { Data } from './Data';

const Events = () => {
  const {
    is: control,
    situations,
    typeOptions,
    formdata
  } = Data.use();

  column: {
    flex: 1;
  }

  Form: {
    flexDirection: row;
  }

  <Provider for={control}>
    <Page>
      <Description />
      <Form>
        <column>
          <Select
            label="Exposure Situation"
            field="exposureSituationUuid"
            // initial={row => row.exposureSituationUuid}
            initial={row => undefined}
            options={situations}
          />
          <Select
            key={uniqueId(formdata)}
            label="Hazard Source"
            options={typeOptions}
            onChange={(selected, data) => {
              data.sourceType = selected.type;
              data.sourceUuid = selected.uuid;
            }}
            initial={row => {
              return undefined;

              // const uuid =
              //   row.humanTaskUuid ||
              //   row.malfunctionUuid ||
              //   row.misuseUuid ||
              //   null;
  
              // for(const x of typeOptions)
              //   for(const y of x.options)
              //     if(y.value.uuid === uuid)
              //       return y.value;
            }}
          />
        </column>
        <TextArea label="Description" />
        <TextArea label="Remarks" />
      </Form>
      <Table>
        <SelectColumn />
        <Column
          name="ID"
          size={100}
          value="hrId"
        />
        <Column
          name="Exposure Situation"
          size={130}
          value={row => row.exposureSituation.hrId}
        />
        <Column
          name="Type"
          size={100}
          cell={Type}
        />
        <Column name="Description" />
        <Column name="Remarks" />
      </Table>
    </Page>
  </Provider>
}

const Description = () => {
  <ToolTip>
    <p>
      A Hazardous event is a combination of an <b>Exposure Situation</b> with a 
      <b>Malfunction</b>, <b>Misuse</b>, or <b>Human Task</b> (mutually exclusive).
    </p>
    <p>
      An example of this may be "Robot is deployed and in 
      motion while traversing at a higher speed than intended".
    </p>
  </ToolTip>
}

const Type = ({ row }) => {
  const { humanTask, malfunction, misuse } = row;

  const type =
    humanTask ? "Human Task" :
    malfunction ? "Malfunction" :
    misuse ? "Misuse" :
    null;

  const value =
    humanTask?.description ||
    malfunction?.description ||
    misuse?.description ||
    null;

  display: block;
  flexAlign: center;

  container: {
    marginRight: auto;
    textAlign: center;
  }

  type: {
    radius: round;
    fontSize: 0.75;
    fontWeight: 600;
    marginB: 4;
    padding: 0, 0.8;
    lineHeight: 1.9;

    if(type === "Misuse"){
      color: 0xff7a7a;
      border: 0xffc0c0;
      background: 0xffe1e1;
    }
    else if(type === "Human Task"){
      color: 0x7aa6ff;
      border: 0xc0d0ff;
      background: 0xe1e8ff;
    }
    else {
      color: 0xffa166;
      border: 0xffc39e;
      background: 0xfff2e0;
    }
  }

  <container>
    <type>
      {type}
    </type>
    {value}
  </container>
}

export default Events;