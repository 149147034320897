import { useLayoutEffect, useState } from 'react';

const ANIMATE_ACTIVE = new Set();

const Animate = ({ children }) => {
  const visible = useAnimatedEnter();

  forward: className;

  if(!visible){
    opacity: 0;
    transform: "translateY(-10px)";
  }
  else {
    opacity: 1;
    transition: "opacity 0.25s cubic-bezier(.27,.46,.49,1.04), transform 0.25s cubic-bezier(.27,.46,.49,1.04)";
  }

  <this>
    {children}
  </this>
}

function useAnimatedEnter(){
  const [visible, setVisible] = useState(false);

  useLayoutEffect(() => {
    const delay = ANIMATE_ACTIVE.size * 25;

    ANIMATE_ACTIVE.add(setVisible);

    setTimeout(() => {
      ANIMATE_ACTIVE.delete(setVisible);
      setVisible(true);
    }, delay);
  }, []);
  
  return visible;
}

export default Animate;