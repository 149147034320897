import { Provider } from '@expressive/mvc';
import { useState } from 'react';
import Table, { Column } from 'table/Table';
import Page from 'wizard/Page';
import ToolTip from 'wizard/ToolTip';

import Data from './Data';
import Generate from './Generate';
import Garbaj from './trash2.svg';

const Situations = () => {
  const { is: control, ceaTypes } = Data.use();

  <Provider for={control}>
    <Page>
      <Description />
      <Generate />
      {!!ceaTypes.size &&
        <Table>
          {ceaTypes.forEach(({ uuid, name }) => (
            <Column name={name} value={row => (
              row.ceaList.find(x => x.typeUuid == uuid).value
            )} />
          ))}
          <Column cell={DeleteButton} head={false} size="24" />
        </Table>
      }
    </Page>
  </Provider>
}

const Description = () => {
  <ToolTip>
    <p>
      An Exposure Situation is a particular situation that results from a unique combination of each 
      <a href="#">Considered Exposure Attributes <b>(CEA)</b></a> value, where one value is chosen for each type. 
    </p>
    <p>
    Below is a list of your CEA Types along with their CEAs.
    Combine these attributes together to create relevant exposure situations.
    Remember, not all combinations can exist together!
    </p>
  </ToolTip>
}

const DeleteButton = ({ row }) => {
  const { remove } = Data.get();

  fontSize: 20;
  userSelect: no-select;
  WebkitUserSelect: no-select;
  cursor: pointer;
  color: $text3;
  
  Garbaj: {
    transition: "all 0.06s ease-in-out"

    css: hover: {
      color: 0xe48383;
      transform: "scale(1.2)";
      transformOrigin: center;
    }
  }

  <Garbaj onClick={() => remove(row)} />
}

export default Situations;