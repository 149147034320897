import { useState } from "react";

/** Must not be undefined. */
type Any = string | number | bigint | symbol | object | boolean | null;

function useAsync<T extends Any>(fn: () => Promise<T>): T | undefined;

function useAsync <T extends readonly Promise<any>[] | []> (x: T):
  readonly [] | { -readonly [P in keyof T]: Awaited<T[P]> };

function useAsync<T extends Any>(
  fn: (() => Promise<T>) | Promise<any>[]){

  const [value, set] = useState<T | any[] | undefined>(() => {

    if(typeof fn == "function")
      fn().then(x => set(x));
    else {
      Promise.all(fn).then(x => set(x))
      return []
    }
    
    return undefined;
  })

  return value;
}

export default useAsync;