import Checkbox from './Checkbox';

const CheckItem = ({ children, checked, onClick }) => {
  forward: className;
  gridColumns: min, auto;
  alignItems: center;
  radius: 6;
  padding: 5, 10;
  margin: 3, 5;
  userSelect: none;
  WebkitUserSelect: none;
  cursor: pointer;
  border: transparent;

  css: hover: {
    background: 0xf4f4f4;
    borderColor: 0xeee;
  }

  content: {
    marginL: 10;
  }

  span: {
    marginL: 10;
  }

  Checkbox: {
    fontSize: 12;
  }

  <this onClick={onClick}>
    <Checkbox checked={checked} />
    <content>
      { children }
    </content>
  </this>
}

export default CheckItem;