import Checkmark from 'common/icons/checkmark.svg';

const Checkbox = ({
  onClick,
  checked,
  partial
}) => {
  forward: className;
  radius: 4;
  size: 1.3;
  overflow: hidden;
  display: flex;
  justifyContent: center;
  alignItems: center;
  cursor: pointer;
  border: "1px", solid, 0xddd;
  background: $background4;

  Checkmark: {
    display: block;
    color: white;
    fontSize: 1.0;
    margin: 0;
  }

  line: {
    height: 0.2;
    width: 0.8;
    background: 0xaaa;
    radius: 2;
  }

  if(checked){
    background: $pltPrimary;
    borderColor: $pltPrimaryL;

    <Checkmark onClick={onClick} />
  }
  else if(partial)
    <line />
}

export default Checkbox;