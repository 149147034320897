import { SDK as BadgerSDK } from '@badger/gateway-client-sdk';
import { SDK as UserSDK } from '@harmadillo/gateway-client-sdk';
import {
  AUTH_CLIENT_ID,
  DOCKER_COMPOSE,
  GATEWAY_AUTH,
  GATEWAY_BADGER,
  GATEWAY_HARMADILLO,
  LOCATION_ORIGIN,
  NODE_ENV,
} from 'env';
import pkceChallenge from 'pkce-challenge';
import { v4 as uuidv4 } from 'uuid';

const REDIRECT_URI = `${LOCATION_ORIGIN}/oauth`;
const AUTH_RESOURCE = `${GATEWAY_AUTH}/auth`;
const TOKEN_RESOURCE = `${GATEWAY_AUTH}/token`;
const SCOPE = "openid profile email";

/** User SDK */
export const userSDK = new UserSDK({
  baseUrl: GATEWAY_HARMADILLO!,
  queryStringOpts: { arrayFormat: "repeat" },
});

/** Badger SDK */
export const badgerSDK = new BadgerSDK({
  baseUrl: GATEWAY_BADGER,
  queryStringOpts: { arrayFormat: "repeat" }
});

/** Retrieve existing user session; login if does not exist. */
export async function loadUserSession(){
  const path = window.location.pathname;

  let token = path == "/oauth"
    ? await getTokenFromAuthFlow()
    : localStorage.getItem("login_token");

  if(!token)
    if(DOCKER_COMPOSE)
      token = await loginAsDeveloper();
    else {
      await login();
      throw new Error("timeout");
    }

  try {
    const { data: user } = await
      badgerSDK.getMeUser(null, undefined, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

    localStorage.setItem("login_token", token);

    for(const sdk of [userSDK, badgerSDK])
      sdk.__setHeaders((x: any) => ({
        ...x,
        Authorization: `Bearer ${token}`
      }));

    return { token, user };
  }
  catch(err: any){
    localStorage.removeItem("login_token");

    if(err.status === 401)
      await login();

    throw err;
  }
}

/** Login as fake user, skipping OAuth. For docker-compose only. */
async function loginAsDeveloper(){
  return badgerSDK
    .getUserTestToken(null, {
      email: "foo.bar@harmadillo.net",
      firstName: "Foo",
      lastName: "Bar"
    })
    .then(res => res.data.token);
}

/** Begin the OAuth login flow. */
export function login(){
  const state = uuidv4();
  const {
    code_challenge,
    code_verifier
  } = pkceChallenge(43);

  const loginPass =
    window.location.pathname === "/login-pass" || undefined;

  localStorage.setItem("challengeVerifier", code_verifier);
  localStorage.setItem("pageState", state);

  const query = encodeQueryParams({
    response_type: "code",
    client_id: AUTH_CLIENT_ID,
    redirect_uri: REDIRECT_URI,
    scope: SCOPE,
    state,
    access_type: "offline",
    code_challenge,
    code_challenge_method: "S256",
    loginPass
  })

  return redirect(`${AUTH_RESOURCE}?${query}`);
}

/** Confirm logout and clear login. Redirect afterwards. */
export async function logout(){
  if(!window.confirm("Do you want to log out?"))
    return;

  localStorage.removeItem("login_token");

  const logoutUrl = NODE_ENV === "production"
    ? `${GATEWAY_AUTH}/logout?redirect_uri=${LOCATION_ORIGIN}`
    : LOCATION_ORIGIN;

  return redirect(logoutUrl);
}

/** Obtain user info and token from an OAuth generated code. */
export async function getTokenFromAuthFlow(){
  const query = decodeQueryParams(window.location.search);

  if (!query || !query.code || !query.state)
    throw new Error("Valid OAuth query parameters not found.");

  window.history.replaceState({}, document.title, "/");

  const { code, state } = query;
  const savedState = localStorage.getItem("pageState");
  const code_verifier = localStorage.getItem("challengeVerifier");

  localStorage.removeItem("challengeVerifier");

  if(state !== savedState || code_verifier == null)
    throw new Error("Valid OAuth query parameters not found.");

  const request = fetch(TOKEN_RESOURCE, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      "Accept": "application/json"
    },
    body: encodeQueryParams({
      code,
      code_verifier: code_verifier!,
      client_id: AUTH_CLIENT_ID,
      grant_type: "authorization_code",
      redirect_uri: REDIRECT_URI
    })
  });

  const res = await request.then((x) => x.json());

  if("error_description" in res)
    throw new Error(res.error_description);

  if("error" in res)
    throw new Error(res.error);

  if("access_token" in res)
    return res.access_token as string;
}

/** Convert object to encoded URI component. */
function encodeQueryParams(
  data: { [key: string]: string | boolean | undefined }){

  const params = [] as string[]

  for(const key in data){
    const encodedKey = encodeURIComponent(key);
    const value = data[key];

    if(value === undefined)
      continue;

    const encodedValue = encodeURIComponent(value);

    params.push(encodedKey + "=" + encodedValue)
  }

  return params.join("&");
}

/** Read query parameters from URL or search string. */
function decodeQueryParams(url: string){
  const queryString = url.split("?")[1];

  if(!queryString)
    return null;

  const acc = {} as { [key: string]: string };

  for(const item of queryString.split("&")){
    const [ key, value ] = item.split("=");
    acc[decodeURIComponent(key)] = decodeURIComponent(value);
  }

  return acc;
}

/**
 * Redirect and wait.
 * Expect page to unload but wait 1 second just as a timeout.
 **/
async function redirect(
  to: string, timeout?: number){

  return new Promise<never>((res) => {
    window.location.href = to;
    setTimeout(res, timeout || 1000);
  })
}