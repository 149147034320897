import Person from './static/person.svg';

const Header = ({ logout }) => {
  bg: $background0;

  center: {
    margin: auto;
    position: relative;
    height: 60;
    maxWidth: 1130;
    flexAlign: center, space-between;
    padding: 0, 15;
    boxSizing: border-box;

    css: after: {
      bg: $outline1;
      absolute: fill-bottom, -1, 0;
      height: 2;
      radius: 2;
    }
  }

  <center>
    <Logo />
    <CurrentUser onClick={logout} />
  </center>
}

const Logo = () => {
  flexAlign: center;

  img: {
    height: 35;
  }

  span: {
    color: $text3;
    font: 18;
    marginL: 12;
  }

  header: {
    font: 18;
    marginL: 10;
    color: $text2;
  }

  <this>
    <img src="./static/logo.png" />
    <header>
      Harmadillo
    </header>
  </this>
}

const CurrentUser = () => {
  bg: $background1;
  border: $outline2;
  color: $accent1;
  cursor: pointer;
  flexAlign: center;
  fontWeight: bold;
  radius: round;
  size: 35;

  Person: {
    forward: onClick;
    font: 17;
    color: $text2;
  }

  <Person />
}

export default Header;