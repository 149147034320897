import "./style/colors.css";
import "./style/fonts.css";

import { setProject, setSDK } from 'api/User';
import { useMemo } from 'react';
import { setErrorHandler } from 'utility/onError';
import Wizard, { Route } from 'wizard/Wizard';

import Assumptions from './pages/Assumptions';
import CEAs from './pages/cea/CEAs';
import CEATypes from './pages/CEATypes';
// import CEAs from './pages/cea-v2/CEAs';
import Situations from './pages/exposure/Situations';
import Functions from './pages/Functions';
import Events from './pages/hazardous/Events';
import Hazards from './pages/hazards/Hazards';
import HumanTasks from './pages/HumanTasks';
import Malfunctions from './pages/Malfunctions';
import Misuses from './pages/Misuses';
import SREMs from './pages/SREMs';

const App = (props) => {
  useWizardProps(props);

  <Wizard {...props}>
    <Route
      name="Assumptions"
      component={Assumptions}
    />
    <Route
      name="SREM"
      header="Safety Related External Measures (SREMs)"
      component={SREMs}
    />
    <Route
      name="CEA Types"
      header="Considered Exposure Attribute (CEA) Types"
      component={CEATypes}
    />
    <Route
      name="CEA"
      header="Considered Exposure Attributes (CEAs)"
      component={CEAs}
    />
    <Route
      name="Exposure Situations"
      component={Situations}
    />
    <Route
      name="Functions"
      component={Functions}
    />
    <Route
      name="Malfunctions"
      component={Malfunctions}
    />
    <Route
      name="Misuses"
      component={Misuses}
    />
    <Route
      name="Human Tasks"
      component={HumanTasks}
    />
    <Route
      name="Hazardous Events"
      component={Events}
    />
    <Route
      name="Hazards"
      component={Hazards}
    />
  </Wizard>
}

function useWizardProps(props){
  const {
    sdk,
    projectUuid,
    onDone,
    onError
  } = props;

  useMemo(() => {
    if(sdk)
      setSDK(sdk);
    else
      throw new Error("User SDK was not provided to wizard.")

    if(projectUuid)
      setProject(projectUuid)
    else
      throw new Error("Project Uuid was not provided to wizard.");

    if(onError)
      setErrorHandler(onError);

    void onDone;
  }, [sdk, projectUuid]);
}

export default App;