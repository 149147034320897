import { Provider } from '@expressive/mvc';
import { useCallback, useMemo } from 'react';
import Head from 'table/Head';
import SelectColumn from 'table/Select';
import Table, { Column } from 'table/Table';
import { range } from 'utility/range';
import Form from 'wizard/Form';
import Input from 'wizard/Input';
import { Columns } from 'wizard/Page';
import Select from 'wizard/Select';
import TextArea from 'wizard/TextArea';
import ToolTip from 'wizard/ToolTip';

import { Data } from './Data';

const CEAs = () => {
  const {
    is: control,
    typeFilterOptions,
    typeSelectOptions,
    frequencySelectOptions
  } = Data.use();

  row: {
    marginB: 14;
  }

  Form: {
    flexDirection: column;
    justifyContents: stretch;
  }

  <Provider for={control}>
    <Columns>
      <Description />
      <Form>
        <Select
          multiple
          label="CEA Type"
          field="typeUuid"
          initial={row => row.type.uuid}
          options={typeSelectOptions}
        />
        <Input
          required
          label="Value"
          field="value"
        />
        <Select
          multiple
          label="Frequency"
          field="ceaFrequencyDiscreteUuid"
          initial={row => row.frequency?.discreteValueUuid}
          options={frequencySelectOptions}
        />
        <Select
          multiple
          label="Duration"
          field="duration"
          initial={row => row.duration?.value}
          options={range(11, n => ({
            value: n/10,
            label: `${n*10}%`
          }))}
        />
        <TextArea
          multiple
          label="Description"
        />
      </Form>
      <Table head={Head}>
        <SelectColumn />
        <Column
          name="Type"
          options={typeFilterOptions}
          value={row => row.type.name}
        />
        <Column name="Value" value="value" />
        <Column name="Description" />
        <Column
          name="Frequency"
          value={row => row.frequency.discreteValue?.label}
          sortBy={row => row.frequency.discreteValue?.value}
        />
        <Column
          name="Duration"
          sortBy={row => row.duration.value}
          value={row => {
            return Math.floor(row.duration.value * 100) + "%";
          }}
        />
      </Table>
    </Columns>
  </Provider>
}

const Description = () => {
  <ToolTip>
    <p>
      A Considered Exposure Attribute is a sub-attribute that is associated with a
      previously defined CEA Type. This may include 'laden' or 'unladen' for Load Status,
      or 'forward' or 'reverse' for Motion Type. Keep in mind that these CEAs will be
      paired together to create a comprehensive list of exposure situations, which
      can grow exponentially with an increasing number of CEAs. Please note that
      CEAs should be mutually exclusive for each CEA type. Also, broader CEAs are more
      efficient than specific ones.
    </p>
    <p>
      For example, for a CEA Type of Flooring, it is better to have broad, mutually
      exclusive CEAs such as 'rough' and 'smooth', rather than listing out specific
      flooring options, such as 'hardwood', 'glass', or 'carpet'.
    </p>
  </ToolTip>
}

export default CEAs;