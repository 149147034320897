import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';

window.addEventListener("load", async () => {
  const container = document.getElementById("react-root");
  const react = createRoot(container);

  react.render(<App/>);
});