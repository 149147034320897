import { get, Provider } from '@expressive/mvc';
import User, { inProject } from 'api/User';
import Manager from 'table/Manager';
import SelectColumn from 'table/Select';
import Table, { Column } from 'table/Table';
import Form from 'wizard/Form';
import { Columns } from 'wizard/Page';
import Select from 'wizard/Select';
import TextArea from 'wizard/TextArea';
import ToolTip from 'wizard/ToolTip';

class Data extends Manager {
  type = User.Malfunction;

  functions = get(async () => {
    const list = await inProject(User.Function);

    return list.map(x => ({
      value: x.uuid,
      label: x.description
    }))
  }, false)

  guidewords = get(async () => {
    const list = await User.Guideword.list();

    return list.map(x => ({
      value: x.uuid,
      label: x.value
    }))
  }, false)
}

const Malfunctions = () => {
  const {
    is: control,
    functions,
    guidewords
  } = Data.use();

  Form: {
    display: flex;
    flexDirection: column;
  }

  <Provider for={control}>
    <Columns>
      <Description />
      <Form>
        <Select
          label="Function"
          options={functions}
          field="functionUuid"
          initial={row => row.functionUuid}
        />
        <Select
          label="Guideword"
          options={guidewords}
          field="guidewordUuid"
          initial={row => row.guidewordUuid}
        />
        <TextArea
          label="Resulting Malfunction"
          field="description"
        />
        <TextArea label="Remarks" />
      </Form>
      <Table>
        <SelectColumn />
        <Column name="Function" value={row => row.function.description} />
        <Column name="Guideword" value={row => row.guideword.value} />
        <Column name="Result" value={row => row.description} />
        <Column name="Remarks" />
      </Table>
    </Columns>
  </Provider>
}

const Description = () => {
  <ToolTip>
    <p>
      A Malfunction is a previously defined function paired with a guideword to
      identify the intended and unintended functions that may occur in a system's
      operating environment. Please note, the guideword is simply for creativity
      purposes and not meant to be restrictive.
    </p>
    <p>
      For example, pairing the Function "Automated traversal from point A to B"
      with "More than intended" might produce the Malfunction of a "Robot travels
      at a higher speed than intended"
    </p>
  </ToolTip>
}

export default Malfunctions;