import BlueButton from 'common/button/BlueButton';
import { useState } from 'react';
import ReactSelect from 'react-select';

import Auth from './Auth';

const SelectProject = () => {
  const { projects, is: auth } = Auth.tap();
  const [ selected, select ] = useState();

  bg: $background1;
  flexAlign: center, down;

  img: {
    width: 80;
    marginB: 20;
  }

  form: {
    bg: $background0;
    border: $outline2;
    alignItems: center;
    padding: 50;
    radius: 10;
  }

  ReactSelect: {
    textAlign: left;
    width: 200;
  }

  BlueButton: {
    width: 80;
    margin: 30, auto, 0;
  }

  <this>
    <img src="./static/logo.png" />
    <h2>Select Project</h2>
    <form>
      <ReactSelect
        options={projects}
        onChange={x => select(x.value)}
      />
      <BlueButton
        disabled={!selected}
        onClick={() => {
          auth.projectUuid = selected;
        }}>
        Continue
      </BlueButton>
    </form>
  </this>
}

export default SelectProject;