import { Input as Managed } from 'common/input/Managed';
import { useMemo } from 'react';
import Manager from 'table/Manager';
import uniqueId from 'utility/uniqueId';

const Input = ({
  label,
  field,
  multiple,
  initial: getInitial,
  ...rest
}) => {
  const { formdata, selected } = Manager.tap();
  const name = field || label.toLowerCase();
  const memoDeps = [
    selected.size,
    uniqueId(formdata)
  ];

  const key = useMemo(() => {
    formdata[name] = "";
    return Math.random();
  }, memoDeps);

  const initial = useMemo(() => {
    if(typeof getInitial !== "function")
      getInitial = (row) => row[name];

    return Array
      .from(selected)
      .map(getInitial)
      .reduce((p, n) => (
        p && n !== p ? undefined : n
      ), undefined);
  }, memoDeps);

  Managed: {
    forward: className;
    margin: 5;
    font: 14;
    flex: 1;
  }
  
  <Managed
    {...rest}
    key={key}
    label={label}
    initial={initial}
    disabled={!multiple && selected.size > 1}
    onUpdate={(value) => {
      formdata[name] = value;
    }}
  />
}

export default Input;