import { Provider } from '@expressive/mvc';
import User from 'api/User';
import Manager from 'table/Manager';
import Select from 'table/Select';
import Table, { Column } from 'table/Table';
import Form from 'wizard/Form';
import { Columns } from 'wizard/Page';
import TextArea from 'wizard/TextArea';
import ToolTip from 'wizard/ToolTip';

class Data extends Manager {
  type = User.Function;
}

const Functions = () => {
  <Provider for={Data}>
    <Columns>
      <ToolTip>
        <p>
          A Function is any task that the system is expected to perform in its operating
          environment. It is important to think about how the system interacts with its
          environment without listing out every feature of the system. A function should
          be high level enough to be separate from the actual design and features of the
          system, but low level enough not to restate the operational scenarios.
        </p>
        <p>
          An example might include: Automated traversal from point A to B.
        </p>
      </ToolTip>
      <Form>
        <TextArea label="Description" />
        <TextArea label="Remarks" />
      </Form>
      <Table>
        <Select />
        <Column name="Description" />
        <Column name="Remarks" />
      </Table>
    </Columns>
  </Provider>
}

export default Functions;