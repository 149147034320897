import { Provider } from '@expressive/mvc';
import User from 'api/User';
import Manager from 'table/Manager';
import Select from 'table/Select';
import Table, { Column } from 'table/Table';
import Form from 'wizard/Form';
import { Columns } from 'wizard/Page';
import TextArea from 'wizard/TextArea';
import ToolTip from 'wizard/ToolTip';

class Data extends Manager {
  type = User.HumanTask;
}

const HumanTasks = () => {
  <Provider for={Data}>
    <Columns>
      <ToolTip>
        <p>
          A Human Task is a task that a human may perform in the system's operating
          environment. An example of this might be a technician performing maintenance
          on a robot.
        </p>
      </ToolTip>
      <Form>
        <TextArea label="Description" />
      </Form>
      <Table>
        <Select />
        <Column name="Name" size={200} value={row => row.hrId} />
        <Column name="Description" />
      </Table>
    </Columns>
  </Provider>
}

export default HumanTasks;