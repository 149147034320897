import IconOn from "./lightbulb_on.svg";
import IconOff from "./lightbulb_off.svg";

const LightBulb = ({ on }) => {
  icon: {
    forward: className;
  }

  if(on)
    <IconOn icon />
  else
    <IconOff icon />
}

export default LightBulb;