export const BlueButton = (props) => {
  const {
    children,
    disabled,
    onClick,
    ...rest
  } = props;

  forward: className;
  bg: $pltPrimary;
  border: $pltPrimaryL;
  shadow: $shadow1, 5;
  flexAlign: center;
  font: 15;
  padding: 0.5, 1.5;
  radius: 8;
  color: white;
  clickable: true;
  marginT: 16;
  transition: "all 0.1s ease-in-out";

  css: active: {
    transform: "scale(0.98)"
  }

  if(disabled){
    filter: "brightness(80%)";
    opacity: 0.3;
    cursor: not-allowed;
    transform: "scale(1) !important"
  }

  <this {...rest} onClick={disabled ? undefined : onClick}>
    {children}
  </this>
}

export default BlueButton;