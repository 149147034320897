import { TextArea as Managed } from 'common/input/Managed';
import { useMemo } from 'react';
import Manager from 'table/Manager';
import uniqueId from 'utility/uniqueId';

const TextArea = ({
  label,
  field,
  multiple,
  initial: getInitial,
}) => {
  const { formdata, selected } = Manager.tap();
  const disabled = !multiple && selected.size > 1;
  const name = field || label.toLowerCase();
  const deps = [selected.size, uniqueId(formdata)];

  const key = useMemo(() => {
    formdata[name] = "";
    return Math.random();
  }, deps);

  const initial = useMemo(() => {
    if(typeof getInitial !== "function")
      getInitial = (row) => row[name];

    return Array
      .from(selected)
      .map(getInitial)
      .reduce((p, n) => (
        p && n !== p ? undefined : n
      ), undefined);
  }, deps);

  Managed: {
    forward: className;
    minHeight: 120;
    margin: 5;
    flex: 1;
    font: 14;
  }

  <Managed
    key={key}
    label={label}
    initial={initial}
    disabled={disabled}
    onUpdate={(value) => {
      formdata[name] = value;
    }}
  />
}

export default TextArea;