import { Provider } from '@expressive/mvc';
import User from 'api/User';
import Manager from 'table/Manager';
import Select from 'table/Select';
import Table, { Column } from 'table/Table';
import Form from 'wizard/Form';
import { Columns } from 'wizard/Page';
import TextArea from 'wizard/TextArea';
import ToolTip from 'wizard/ToolTip';

class Data extends Manager {
  type = User.CEAType;

  delete(row){
    return User.sdk.deleteCEAType({
      ceaTypeUuid: row.uuid
    },
    { confirmed: true })
  }
}

const CEATypes = () => {
  <Provider for={Data}>
    <Columns>
      <Description />
      <Form>
        <TextArea label="Name" />
        <TextArea label="Description" />
      </Form>
      <Table>
        <Select />
        <Column name="Name" />
        <Column name="Description" size={"2.0fr"} />
      </Table>
    </Columns>
  </Provider>
}

const Description = () => {
  <ToolTip>
    <p>
      A CEA Type is a general characteristic trait about the operation of a system
      that helps the user categorize other attributes that may occur throughout
      the product's lifecycle. CEA Types will be the basis for creating the
      system's exposure situations. It's best practice to create a CEA Type
      if the changing attribute impacts the level of risk in a situation.
      Examples of a CEA Type might include the system's Mode, Motion State,
      Load Status, or Obstacle Exposure.
    </p>
  </ToolTip>
}

export default CEATypes;