import { useMemo, useState } from 'react';

import DataGrid from './DataGrid';
import FilterMenu from './FilterMenu';
import Filter from './icon/filter.svg';
import Swap from './icon/swap.svg';

const Head = ({ column, children }) => {
  const { sortColumn, sortDirection, sort } = DataGrid.tap();

  const hasOptions = !!column.props.options;
  const [active, setActive] = useState(false);
  const toggle = () => sort(column);

  display: flex;
  alignItems: center;
  justifyContent: space-between;
  cursor: pointer;
  WebkitUserSelect: none;
  userSelect: none;
  position: relative;

  Filter: {
    color: 0xccc;
    font: 12;
    css: hover: {
      transform: "scale(1.2)"
    }
  }
  
  <this>
    <span onClick={toggle}>
      {children}
    </span>
    <buttons>
      <SortButton
        onClick={toggle}
        mode={column == sortColumn && sortDirection}
      />
      {hasOptions && (
        <Filter button onClick={() => setActive(!active)} />
      )}
    </buttons>
    {hasOptions && (
      <FilterMenu
        column={column}
        active={active}
        toggle={setActive}
      />
    )}
  </this>
}

export default Head;

const SortButton = ({ mode, onClick }) => {
  Swap: {
    color: 0xccc;
    transform: "rotate(90deg)";

    if(mode === 1)
      color: $pltPrimary;
    else if(mode === -1)
      color: orange;
  };
  
  <Swap onClick={onClick} />
}