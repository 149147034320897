import { useCallback, useLayoutEffect, useRef } from 'react';

import BaseInput from './Input';
import BaseTextArea from './TextArea';
import useControlled from './useControlled';

export const Input = (props) => {
  const {
    children,
    initial,
    onCreate,
    onUpdate,
    onEnterKey,
    ...rest
  } = props;

  const ref = useControlled({
    children,
    initial,
    onCreate,
    onUpdate,
    onEnterKey,
  });

  <BaseInput ref={ref} {...rest} />
}

export const TextArea = (props) => {
  const {
    children,
    initial,
    onCreate,
    onUpdate,
    onEnterKey,
    ...rest
  } = props;

  const ref = useControlled({
    children,
    initial,
    onCreate,
    onUpdate,
    onEnterKey,
  });

  <BaseTextArea ref={ref} {...rest} />
}