import { useState } from 'react';

const SelectBar = ({
  options,
  onSelect,
  label
}) => {
  const [ active, setActive ] = useState(options[0]);

  forward: className;
  minWidth: 100;
  position: relative;
  overflow: auto;
  padding: 10, 5;
  paddingR: 8;
  textAlign: center;

  css: after: {
    width: 2;
    radius: 2;
    bg: 0xeee;
    absolute: fill-right, 10, 0;
  }

  item: {
    padding: 5, 15;
    margin: 2, 0;
    radius: 5;
    cursor: pointer;
    WebkitUserSelect: none;

    css: hover: {
      bg: 0xf7f7f7;
    }
  }

  <this>
    {options.map((page, i) => {
      item: {
        onClick = () => {
          setActive(page);

          if(onSelect)
            onSelect(page);
        }

        if(active == page){
          bg: 0xeee;
          color: $pltPrimary;
        }
      }
      
      <item key={i}>
        {label ? label(page) : page}
      </item>
    })}
  </this>
}

export default SelectBar;