import { SDK } from '@harmadillo/gateway-client-sdk';

import { getEntities } from './entities';
import Abstract from './SDK';

declare namespace User {
  type Entities = Abstract.Types<SDK>;
}

const User = {} as Abstract.Entities<SDK>;

let PROJECT_UUID = "";

export const getProject = () => PROJECT_UUID;
export const setProject = (uuid: string) => PROJECT_UUID = uuid;

export const setSDK = (sdk: SDK) => getEntities(sdk, User);

export const inProject = <T extends Abstract.Entity> (type: T) =>
  type.list({ projectUuid: PROJECT_UUID }) as ReturnType<T["list"]>;

export default User;