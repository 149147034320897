import css from '@expressive/css';
import { Provider } from '@expressive/mvc';
import PleaseHold from 'common/spinner/PleaseHold';
import { Suspense } from 'react';

import Control from './Control';
import WizardNav from './WizardNav';

export const Wizard = ({ children, onDone, onNext }) => {
  const wizard = Control.use({ onDone, onNext });

  container: {
    forward: className, style;
    height: fill;
    gridRows: min, "minmax(0, 1fr)";
    gridColumns: "minmax(600px, 1150px)";
    boxSizing: border-box;
    justifyContent: center;
    fontSize: 14;
    lineHeight: 1.6;
    textAlign: left;
  }

  <Provider for={wizard}>
    <container id="wizard-ui">
      {children}
      <WizardNav />
      <WizardBody />
    </container>
  </Provider>
}

const WizardBody = () => {
  const { current } = Control.router();

  <Suspense fallback={<PleaseHold />}>
    {current && <current.component />}
  </Suspense>
}

export default Wizard;

export const Route = (props) => {
  Control.addPage(props);
  return null;
}

css(`
  #wizard-ui b {
    font-weight: bold;
  }

  #wizard-ui ul {
    margin-top: 1em;
    margin-bottom: 1em;
    padding-left: 40px;
  }

  #wizard-ui p {
    margin-top: 1em;
    margin-bottom: 1em;
  }
`, {});